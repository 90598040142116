<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" :content="AddorEdit">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <el-steps v-if="!id" :active="steps" finish-status="success" simple style="margin-top: 20px">
                    <el-step title="基本信息"></el-step>
                    <el-step title="诊断信息"></el-step>
                    <el-step title="完成"></el-step>
                </el-steps>
                <el-scrollbar class="left-nav-wrap" style="height:calc(100% - 90px);margin-top: 20px">
                    <div v-show="steps == 0">
                        <el-form :model="AddForm" ref="AddForm" label-width="100px" :rules="rules" style="width: 500px">
                            <el-form-item label="患者姓名" prop="name">
                                <el-input v-model="AddForm.name"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" prop="sex">
                                <el-radio-group v-model="AddForm.sex">
                                    <el-radio :label="true">男</el-radio>
                                    <el-radio :label="false">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="年龄" prop="age">
                                <el-input v-model="AddForm.age" type="number"></el-input>
                            </el-form-item>
                            <el-form-item label="联系方式" prop="phone">
                                <el-input v-model="AddForm.phone"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号" prop="idNo">
                                <el-input v-model="AddForm.idNo"></el-input>
                            </el-form-item>
                            <el-form-item label="地区" prop="regionId">
                                <el-cascader :placeholder="AddForm.fullName" v-model="AddForm.regionId"
                                    :options="RegionData" size="medium"
                                    :props="{ emitPath: false, multiple: false, checkStrictly: true, lazy: true, lazyLoad: LasyLoadRegion, value: 'id', label: 'regionName', children: 'childrens' }"
                                    style="margin-right: 10px;width: 100%;">
                                </el-cascader>
                            </el-form-item>
                            <el-form-item label="详细地址" prop="address">
                                <el-input v-model="AddForm.address"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="nextBtn('AddForm')">{{ btnText }}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-show="steps == 1">
                        <el-form :model="diagnoseForm" ref="diagnoseForm" :rules="rules" label-width="100px"
                            style="width: 1100px">
                            <el-form-item label="主诉" prop="main">
                                <el-input v-model="diagnoseForm.main"></el-input>
                            </el-form-item>
                            <el-form-item label="现病史">
                                <el-input type="textarea" v-model="diagnoseForm.current"></el-input>
                            </el-form-item>
                            <el-form-item label="既往史">
                                <el-input type="textarea" v-model="diagnoseForm.past"></el-input>
                            </el-form-item>
                            <el-form-item label="过敏史">
                                <el-input v-model="diagnoseForm.Irritability"></el-input>
                            </el-form-item>
                            <el-form-item label="家族史">
                                <el-input v-model="diagnoseForm.family"></el-input>
                            </el-form-item>
                            <el-form-item label="疾病诊断" prop="diseaseDiagnose">
                                <el-input v-model="diagnoseForm.diseaseDiagnose"></el-input>
                            </el-form-item>
                            <el-form-item label="体格检查">
                                <el-descriptions class="margin-top" :column="4" border>
                                    <el-descriptions-item label="体温">
                                        <el-input class="small-input" v-model="diagnoseForm.temperature" size="small">
                                        </el-input>℃
                                    </el-descriptions-item>
                                    <el-descriptions-item label="身高">
                                        <el-input class="small-input" v-model="diagnoseForm.height" size="small">
                                        </el-input>CM
                                    </el-descriptions-item>
                                    <el-descriptions-item label="体重">
                                        <el-input class="small-input" v-model="diagnoseForm.weight" size="small">
                                        </el-input>KG
                                    </el-descriptions-item>
                                    <el-descriptions-item label="BMI">
                                        <el-input class="small-input" v-model="diagnoseForm.BMI" size="small">
                                        </el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="心率">
                                        <el-input class="small-input" v-model="diagnoseForm.heartRate" size="small">
                                        </el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="血压">
                                        <el-input class="small-input" v-model="diagnoseForm.heightTension" size="small">
                                        </el-input>/<el-input v-model="diagnoseForm.lowTension" class="small-input"
                                            size="small"></el-input>mmHg
                                    </el-descriptions-item>
                                    <el-descriptions-item label="呼吸">
                                        <el-input class="small-input" v-model="diagnoseForm.breathe" size="small">
                                        </el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="脉搏">
                                        <el-input class="small-input" v-model="diagnoseForm.Pulse" size="small">
                                        </el-input>
                                    </el-descriptions-item>

                                </el-descriptions>
                            </el-form-item>
                            <el-form-item label="症状照片">
                                <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card"
                                    :limit="5" multiple :on-preview="DiagnoseImagePreview"
                                    :on-remove="DiagnoseImageRemove" :on-success="DiagnoseImageUpload"
                                    :file-list="ImageContentList">
                                    <i class="el-icon-plus"></i>
                                    <div class="el-upload__tip" slot="tip">最多上传5张</div>
                                </el-upload>
                                <el-dialog :visible.sync="Image.dialogVisible">
                                    <img width="100%" :src="Image.dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                            <el-form-item label="上传处方">
                                <el-upload :headers="headers" :action="FileUploadUrl" list-type="picture-card"
                                    :limit="5" multiple :on-preview="handleImagePreview" :on-remove="handleImageRemove"
                                    :on-success="handleImageUpload" :file-list="ImageContentList">
                                    <i class="el-icon-plus"></i>
                                    <div class="el-upload__tip" slot="tip">最多上传5张</div>
                                </el-upload>
                                <el-dialog :visible.sync="Image.dialogVisible">
                                    <img width="100%" :src="Image.dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit('diagnoseForm')">保存</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-show="steps == 3">
                        <el-result icon="success" title="添加患者成功!">
                            <template slot="extra">
                                <el-button type="primary" size="medium" @click="goBack()">返回</el-button>
                            </template>
                        </el-result>
                    </div>
                </el-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import { Region } from "../../../components/HospitalDomain/Region";
import { Patient } from "../../../components/HospitalDomain/Patient/Patient";

export default {
    name: "AddPatient",
    computed: {
        headers() {
            return {
                "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
            }
        }
    },
    data() {
        var patient = new Patient(this.TokenClient, this.Services.Authorization)
        var region = new Region(this.TokenClient, this.Services.Authorization);
        return {
            patientDomain: patient,
            Region: region,
            AddorEdit: this.$route.query.title,
            id: this.$route.query.id, //列表页id
            RegionData: [],
            FileUploadUrl: this.Services.Authorization + '/api/File/upload',
            dialogImageUrl: '',
            dialogVisible: false,
            Image: {
                //单击图片上传控件中的图片时，放大显示
                dialogVisible: false,
                dialogImageUrl: '',
            },
            ImageContent: [],//图片列表
            ImageContentList: [],//图片上传控件加载的数据
            DiagnoseContent: [],//图片列表
            steps: 0,
            btnText: '下一步',
            AddForm: {
                organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
                PatientType: 2,
                address: '',
                age: '',
                name: '',
                phone: '',
                regionId: null,
                sex: true,
                idNo: '',
                fullName: '',
            },
            diagnoseForm: {
                type: 2,
                organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
                patientId: 0,
                /*main: "",
                current: "",
                past: "",
                Irritability: "",
                family: "",
                diseaseDiagnose: "",

                BMI: "" ,
                Pulse: "",
                breathe: "",
                heartRate: "",
                height: "",
                heightTension: "",
                lowTension: "",
                temperature: "" ,
                weight:"",*/

                DiagnoseImgList: [],
                PrescriptionImgList: [],
            },
            rules: {
                name: [
                    { required: true, message: '请输入患者姓名', trigger: 'blur' },
                ],
                age: [
                    { pattern: /^[1-9]\d*$/, message: "请输入大于0，小于10000的正整数", trigger: "blur" },
                    { required: true, message: '请输入患者年龄', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入患者联系方式', trigger: 'blur' },
                    { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
                ],
                idNo: [
                    { required: true, message: '请输入身份证号码', trigger: 'blur' },
                    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: "请输入正确格式的身份证号", trigger: "blur" },
                ],
                main: [
                    { required: true, message: '请输入主诉', trigger: 'blur' },
                ],
                diseaseDiagnose: [
                    { required: true, message: '请输入疾病诊断', trigger: 'blur' },
                ],
            }
        }
    },
    mounted() {
        if (this.id) {
            this.getDetail();
            this.btnText = '保存';
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        getDetail() {
            var _this = this;
            _this.patientDomain.GetDetail(_this.id,
                function (data) {
                    _this.AddForm = data.data
                    _this.Region.RegionsDetail(_this.AddForm.regionId,
                        function (data) {
                            _this.$set(_this.AddForm, 'fullName', data.data.fullName)
                        },
                        function (err) {
                        });
                },
                function (err) {

                });
        },
        LasyLoadRegion(node, resolve) {
            var _this = this;
            _this.Region.Regions(node.value,
                function (data) {
                    for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                        data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 3;
                    }
                    resolve(data.data);
                },
                function (err) {
                    resolve([]);
                });
        },
        handleImageUpload(response, file, fileList) {
            var _this = this;
            response.forEach(function (file) {
                var imgarr = {
                    "filePath": file.completeFilePath,
                    "fileName": file.fileName,
                    "fileHash": file.fileHash,
                    "fileExt": file.fileExtension,
                }
                _this.ImageContent.push(imgarr);
            });
            _this.diagnoseForm.PrescriptionImgList = _this.ImageContent;
        },
        handleImagePreview(file) {
            this.Image.dialogImageUrl = file.url;
            this.Image.dialogVisible = true;
        },
        handleImageRemove(file, fileList) {
            var _this = this;
            const filePath = file.response[0].completeFilePath;
            //2.从pics数组中找到图片对应的索引值
            //形参‘x’，是数组pic里的每一项
            const i = _this.ImageContent.findIndex(x =>
                x.completeFilePath == filePath);
            //调用数组的splice方法，把图片的信息对象从pics数组中移除
            _this.ImageContent.splice(i, 1);
            _this.diagnoseForm.PrescriptionImgList = _this.ImageContent;
        },
        DiagnoseImageUpload(response, file, fileList) {
            var _this = this;
            response.forEach(function (file) {
                var imgarr = {
                    "filePath": file.completeFilePath,
                    "fileName": file.fileName,
                    "fileHash": file.fileHash,
                    "fileExt": file.fileExtension,
                }

                _this.DiagnoseContent.push(imgarr);
            });
            _this.diagnoseForm.DiagnoseImgList = _this.DiagnoseContent;
        },
        DiagnoseImagePreview(file) {
            this.Image.dialogImageUrl = file.url;
            this.Image.dialogVisible = true;
        },
        DiagnoseImageRemove(file, fileList) {
            var _this = this;
            const filePath = file.response[0].completeFilePath;
            //2.从pics数组中找到图片对应的索引值
            //形参‘x’，是数组pic里的每一项
            const i = _this.DiagnoseContent.findIndex(x =>
                x.completeFilePath == filePath);
            //调用数组的splice方法，把图片的信息对象从pics数组中移除
            _this.DiagnoseContent.splice(i, 1);
            _this.diagnoseForm.DiagnoseImgList = _this.DiagnoseContent;
        },
        nextBtn(formName) {
            var _this = this;
            if (_this.id > 0) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        _this.patientDomain.patientEdit(_this.AddForm,
                            function (data) {
                                _this.$alert('患者信息修改成功', '提示', {
                                    confirmButtonText: '确定并返回',
                                    type: 'success',
                                    callback: action => {
                                        _this.$router.go(-1)
                                    }
                                });
                            },
                            function (error) {
                                console.log(error);
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            } else {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        _this.patientDomain.patientAdd(_this.AddForm,
                            function (data) {
                                _this.steps = 1;
                                _this.diagnoseForm.patientId = data.data;
                            },
                            function (error) {
                                console.log(error);
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        },
        onSubmit(formName) {
            var _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    _this.patientDomain.DiagnoseAdd(_this.diagnoseForm,
                        function (data) {
                            _this.steps = 3
                        },
                        function (error) {
                            console.log(error);
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>

.el-page-header {
    line-height: 73px;
    padding-left: 20px;
}

.small-input {
    display: inline-block;
    width: 100px;
    margin: 0 5px;
}
</style>
